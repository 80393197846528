import logo from './logo.svg';
import './App.css';



import Main from './Main';
import Transactions from './Transactions';

function App() {
  return (



      <div className="App">
        
        <Main className='main' />
        
        
      </div>
    
  );
}

export default App;
